import { TimeZone } from '~/lib/enum';
import z from 'zod';
import { SCHEMA_FILE } from '~/lib/schema';
import { zodUrlOptional } from '~/lib/zod';
import { FileImageSizes } from '~/.server/drizzle/entity/FileEntity';

export enum MicrositeFileType {
  FLOORPLAN = 'floorplan',
  VIDEO = 'video',
  GALLERY = 'gallery',
}

export interface MicrositeTemplateDataImage {
  index: number;
  name?: string;
  number: number;
  small_1x: string;
  small_2x: string;
  small_3x: string;
  medium_1x: string;
  medium_2x: string;
  medium_3x: string;
  large_1x: string;
  large_2x: string;
}

export interface MicrositeTemplateDataTour {
  index: number;
  url: string;
}

export interface MicrositeTemplateDataVideo extends MicrositeTemplateDataImage {
  name: string;
  m3u8: string;
  mp4: string;
}

export const MicrositeSchemaProfile = z.object({
  name: z.string({ required_error: 'Name is required' }),
  organization: z.string().optional(),
  email: z.string({ required_error: 'Email is required' }).email({ message: 'Invalid email' }),
  phone: z.string().optional(),
  headshot: SCHEMA_FILE.optional(),
  title: z.string().optional(),
  license: z.string().optional(),
  website: zodUrlOptional('Website URL'),
  facebook: zodUrlOptional('Facebook URL'),
  linkedin: zodUrlOptional('LinkedIn URL'),
  twitter: z.string().optional(),
  pinterest: zodUrlOptional('Pinterest URL'),
});

export type MicrositeProfile = z.infer<typeof MicrositeSchemaProfile>;

export interface MicrositeTemplateMeta {
  title_seo: string;
  title_social: string;
  description_seo: string;
  description_social: string;

  og_video_watch?: string;
  og_video_width?: number;
  og_video_height?: number;

  og_image_url?: string;
  og_image_width?: number;
  og_image_height?: number;

  twitter_image?: string;
  twitter_handle?: string;
  twitter_video_watch?: string;
  twitter_video_mp4?: string;
  twitter_video_width?: string;
  twitter_video_height?: string;
}

export interface MicrositeTemplateAddress {
  address1: string;
  address2: string;
  line1: string;
  line2: string | null;
  street: string;
  satellite: string;
}

export interface MicrositeTemplateListing {
  body: string | null;
  body_p: string | null;
  price_number: number | null;
  price_formatted: string | null;
  beds: number | null;
  baths: number | null;
  mls: string | null;
  sqft: number | null;
  built: number | null;
  lot: number | null;
  garage: number | null;
  tags: string[];
}

export interface MicrositeTemplateMedia {
  photos: MicrositeTemplateDataImage[];
  videos: MicrositeTemplateDataVideo[];
  floors: MicrositeTemplateDataImage[];
  pdf?: string;

  hero_large_1x: string | null;
  hero_large_2x: string | null;
  hero_medium_1x: string | null;
  hero_medium_2x: string | null;
  hero_m3u8: string | null;
  hero_mp4: string | null;

  images: MicrositeTemplateDataImage[];
  tours: MicrositeTemplateDataTour[];
}

export interface MicrositeTemplateBranding {
  tenant_logo: string;
  tenant_url?: string;

  brand_name: string | null;
  brand_logo: string | null;
  brand_homepage: string | null;
}

export type MicrositeTemplateData = MicrositeTemplateMeta &
  MicrositeTemplateBranding &
  MicrositeTemplateAddress &
  MicrositeTemplateMedia & {
    timezone: TimeZone;
    gallery?: string;
    tagline?: string;

    listing?: MicrositeTemplateListing;

    video_tours_floors: boolean;

    url: string | null;
    // m3u8: string | null;
    // mp4: string | null;

    profiles: Array<Omit<MicrositeProfile, 'headshot'> & { headshot?: FileImageSizes }>;
  };
